import Icon from "@/v2-ui/icon"

export type StateErrorDefaultProps = {
  className?: string
}
function StateErrorDefault(props: StateErrorDefaultProps) {
  const { className } = props

  return (
    <Icon
      className={className}
      src="not-found"
      size={30}
    />
  )
}

export default StateErrorDefault
