import { Outlet } from "react-router-dom"
import HomeSidebar from "@/v2-console/home/sidebar/HomeSidebar"
import AuthGuard from "@/v2-console/auth/AuthGuard"
import AppOutlet from "@/v2-console/app/AppOutlet"
import Sidebar from "@/v2-layout-ui/sidebar"

// @TODO: check how i can optimize the different outlets we have for the app.
function HomeOutlet() {
  return (
    <AuthGuard>
      <AppOutlet>
        <Sidebar.Provider>
          <HomeSidebar />
          <div
            className="flex flex-1 flex-col px-4 py-6 lg:px-8 overflow-hidden"
          >
            <Outlet />
          </div>
        </Sidebar.Provider>
      </AppOutlet>
    </AuthGuard>
  )
}

export default HomeOutlet
