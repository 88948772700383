import type { ComponentType } from "react"
import { useRef, useMemo, useCallback, useEffect } from "react"
import type { LinkProps, Location } from "react-router-dom"
import { useNavigate as useNavigateLib, useLocation as useLocationLib }
  from "react-router-dom"

export function getQueryParamValue(paramName:string) {
  const url = new URL(window.location.href)
  const queryParams = new URLSearchParams(url.search)
  return queryParams.get(paramName)
}

export function useLocation<T = never>() {
  const location = useLocationLib()
  return location as Omit<Location, "state"> & { state: T }
}

type NavigateOptions = {
  replace?: boolean,
  state?: any
}

const DEFAULT_NAV_OPTIONS = {
  replace: false
}

export function useNavigate() {
  const navigate = useNavigateLib()
  return useCallback((
    to: LinkProps["to"],
    options: NavigateOptions = DEFAULT_NAV_OPTIONS,
    keepParams = true,
    keepHash = true
  ) => {
    let url = to
    if(keepParams) url = `${url}${window.location.search}`
    if(keepHash) url = `${url}${window.location.hash}`
    navigate(url, options)
  }, [
    navigate
  ])
}

export type ParamKeyValuePair = [ string, string ]
export type URLSearchParamsInit = string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams

/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ])
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   })
 */
export function createSearchParams(init: URLSearchParamsInit = "") {
  if(typeof init === "string"
    || Array.isArray(init)
    || init instanceof URLSearchParams
  ) {
    return new URLSearchParams(init)
  }

  const params: ParamKeyValuePair[] = []

  for(const key of Object.keys(init)) {
    const values = init[key]
    if(!values) continue

    if(Array.isArray(values)) {
      for(const value of values) {
        params.push([ key, value ])
      }
    } else {
      params.push([ key, values ])
    }
  }

  return new URLSearchParams(params)
}

/**
 * A convenient wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 */
export function useSearchParams(defaultInit?: URLSearchParamsInit) {
  const defaultSearchParamsRef = useRef(createSearchParams(defaultInit))

  const location = useLocation()
  const searchParams = useMemo(() => {
    const searchParams = createSearchParams(location.search)

    for(const key of defaultSearchParamsRef.current.keys()) {
      if(!searchParams.has(key)) {
        for(const value of defaultSearchParamsRef.current.getAll(key)) {
          searchParams.append(key, value)
        }
      }
    }

    return searchParams
  }, [
    location.search
  ])

  const navigate = useNavigate()
  const setSearchParams = useCallback((
    nextInit: URLSearchParamsInit,
    navigateOptions?: { replace?: boolean, state?: any }
  ) => {
    if(typeof nextInit === "object") {
      nextInit = {
        ...Object.fromEntries(searchParams.entries()),
        ...nextInit
      }
    }

    const params = createSearchParams(nextInit)
    navigate(`${window.location.pathname}?${params}`, navigateOptions, false)
  }, [
    navigate,
    searchParams
  ])

  return [
    searchParams,
    setSearchParams
  ] as const
}

export function lazyRetry<CT, T extends ComponentType<CT>>(
  fn: () => Promise<{ default: T }>,
  attempt = 3,
  interval = 1000
) {
  return new Promise<{ default: T }>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.warn(`${error.message}, retrying ${attempt} more times..`)
          if(attempt === 1) {
            reject(error)
            return
          }
          lazyRetry(fn, attempt - 1, interval)
            .then(resolve, reject)
        }, interval)
      })
  })
}

export function getIsActiveByPath(path: string) {
  return location.pathname === path
}

export function useOnPathnameChange(callback: (location: Location) => void) {
  const location = useLocation()
  useEffect(() => {
    callback(location)
  }, [ callback, location.pathname ])
}

export function getSearchParamsObject(searchParams: URLSearchParams) {
  return Object.fromEntries(searchParams.entries())
}

export function useOnQueryParamsChange(callback: (searchParams: Record<string, string>) => void) {
  const location = useLocation()
  useEffect(() => {
    callback(getSearchParamsObject(new URLSearchParams(location.search)))
  }, [ callback, location.search ])
}

// export function useRedirect(
//   from: string,
//   to: string,
//   guards?: boolean[]
// ) {
//   const match = useMatch(from)
//   const navigate = useNavigate()
//   useEffect(() => {
//     if(!navigate || !to || !match) return
//     if(guards && !guards.some(Boolean)) return
//     navigate(to)
//   }, [
//     navigate,
//     to,
//     match,
//     guards
//   ])
// }

// export function useRouteAuthGuard(isAuthenticated: boolean) {
//   const navigate = useNavigate()
//   const isLogin = useMatch("/login")
//   useEffect(() => {
//     if(isAuthenticated || isLogin) return
//     const r = window.location.pathname
//     const s = window.location.search
//     const to = `/login${r ? `?r=${encodeURIComponent(r + s)}` : ""}`
//     navigate(to)
//   }, [
//     isLogin,
//     navigate,
//     isAuthenticated
//   ])
// }

// export function usePageTitle(title: string) {
//   useEffect(() => {
//     if(!title) return
//     const prev = document.title
//     if(prev === title) return
//     document.title = title
//     return () => {
//       document.title = prev
//     }
//   }, [
//     title
//   ])
// }
