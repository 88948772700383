import type { ReactNode } from "react"
import { useEffect, useRef, useState } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import Popover from "@/v2-ui/popover"
import Button from "@/v2-ui/button"
import { assertStateLoadingType } from "@/v2-layout-ui/state/loading/state.loading.utils"
import { assertStateErrorType } from "@/v2-layout-ui/state/error/state.error.utils"
import { assertStateDataType } from "@/v2-layout-ui/state/data/state.data.utils"
import StateLoading from "@/v2-layout-ui/state/loading/StateLoading"
import type { StateType } from "@/v2-layout-ui/state/state.types"
import StateError from "@/v2-layout-ui/state/error/StateError"
import StateData from "@/v2-layout-ui/state/data/StateData"
import Header from "@/v2-layout-ui/header"

const MINI_CUT_OFF_VALUE = 250

export type StateDisplayProps = {
  stateClassName?: string,
  description?: ReactNode,
  stateType: StateType,
  title?: string
}

function StateDisplay(props: StateDisplayProps) {
  const {
    stateClassName,
    stateType,
    title,
    description
  } = props

  const [ isMini, setIsMini ] = useState(false)

  const fallbackRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if(!stateType) return
    if(fallbackRef.current && title && description) {
      const height = fallbackRef.current.offsetHeight
      const width = fallbackRef.current.offsetWidth
      // Adjust this threshold value depending on when you want to show the icon vs text
      const isMini = Math.min(height, width) < MINI_CUT_OFF_VALUE
      setIsMini(isMini)
    }
  }, [ stateType, title, description ])

  if(!title && description) {
    throw new Error("StateDisplay: description provided without title")
  }

  if(!stateType) {
    return null
  }

  return (
    <div
      ref={fallbackRef}
      className="w-full h-full flex flex-col items-center justify-center"
    >
      {assertStateErrorType(stateType)
        ? <StateError
            stateErrorType={stateType}
            className={stateClassName}
          />
        : null
      }
      {assertStateLoadingType(stateType)
        ? <StateLoading
            stateLoadingType={stateType}
            className={stateClassName}
          />
        : null
      }
      {assertStateDataType(stateType)
        ? <StateData
            stateDataType={stateType}
            className={stateClassName}
          />
        : null
      }
      {title
        ? <div
          className={cn("text-foreground text-sm mt-3", {
              "font-semibold": !!description
            })}
          >
            {title}
          </div>
        : null
      }
      {isMini && description
        ? <Popover>
            <Popover.Trigger>
              <Button
                className="mt-1"
              >
                Mer detaljer
              </Button>
            </Popover.Trigger>
          <Popover.Content>
            <Header
              title={title}
              renderCloseButton={
                <Popover.Close
                  className="ml-auto"
                />
              }
            />
              <div
                className="text-muted-foreground"
              >
                {description}
              </div>
            </Popover.Content>
          </Popover>
        : description
          ? <div
              className="text-muted-foreground text-xs"
            >
              {description}
            </div>
          : null
      }
    </div>
  )
}

export default StateDisplay
