import { Outlet } from "react-router-dom"
import Sidebar from "@/v2-layout-ui/sidebar"
import AppOutlet from "@/v2-console/app/AppOutlet"
import AppSidebar from "@/v2-console/app/sidebar/AppSidebar"
import AppReadyIndicator from "@/v2-console/app/AppReadyIndicator"

// @TODO: check how i can optimize the different outlets we have for the app.
function MapAppOutlet() {
  return (
    <AppOutlet>
      <Sidebar.Provider>
        <AppSidebar />
        <Outlet />
        <AppReadyIndicator />
      </Sidebar.Provider>
    </AppOutlet>
  )
}

export default MapAppOutlet
