import type { ReactNode } from "react"
import { useCallback } from "react"
import type { IconNames } from "@/v2-ui/icon/icon.types"
import DropdownMenu from "@/v2-ui/dropdownMenu"
import Sidebar from "@/v2-ui/sidebar"
import Badge from "@/v2-ui/badge"
import Icon from "@/v2-ui/icon"
import SidebarCollapsibleSubItemSkeleton
  from "@/v2-layout-ui/sidebar/SidebarCollapsibleSubItemSkeleton"
import Button from "@/v2-ui/button"

export type SidebarCollapsibleSubItemProps = {
  label: string,
  iconSrc?: IconNames,
  onClick: () => void,
  badge?: string,
  isLoading?: boolean,
  isActive?: boolean,
  isDisabled?: boolean,
  actionDropdownContent?: ReactNode,
  actionIconSrc?: IconNames,
}
function SidebarCollapsibleSubItem(props: SidebarCollapsibleSubItemProps) {
  const {
    label,
    iconSrc,
    onClick,
    badge,
    isLoading,
    isActive,
    isDisabled,
    actionDropdownContent,
    actionIconSrc = "menu-dots"
  } = props

  const renderSidebarButton = useCallback(() => {
    if(isLoading) {
      return (
        <SidebarCollapsibleSubItemSkeleton showIcon={!!iconSrc} />
      )
    }
    return (
      <Sidebar.Menu.Sub.Button
        isActive={isActive}
        asChild
      >
        <Button
          variant="ghost"
          disabled={isDisabled}
        >
          {iconSrc ? <Icon src={iconSrc} /> : null}
          <span className="truncate">{label}</span>
          {badge && <Badge>{badge}</Badge>}
        </Button>
      </Sidebar.Menu.Sub.Button>
    )
  }, [ isActive, iconSrc, label, isLoading, badge, isDisabled ])

  return (
    <Sidebar.Menu.Sub.Item onClick={onClick}>
      {renderSidebarButton()}
      {actionDropdownContent
        ? (
          <DropdownMenu>
            <DropdownMenu.Trigger asChild>
              <Sidebar.Menu.Action showOnHover>
                <Icon src={actionIconSrc} size={12} />
                <span className="sr-only">More</span>
              </Sidebar.Menu.Action>
            </DropdownMenu.Trigger>
            {actionDropdownContent}
          </DropdownMenu>
        )
        : null
      }
    </Sidebar.Menu.Sub.Item>
  )
}

export default SidebarCollapsibleSubItem
