import { useEffect } from "react"
import { useHomeNavigation, useHomeTenantOpenerState } from "@/v2-console/home/home.utils"
import { ROUTES } from "@/v2-console/router/router.constants"

function HomeRedirect() {
  const { value, hasValue } = useHomeTenantOpenerState()
  const { navigate } = useHomeNavigation()

  useEffect(() => {
    if(hasValue && value.lastVisitedTenantId && value.lastVisitedHomeRoute) {
      navigate(value.lastVisitedHomeRoute, value.lastVisitedTenantId)
      return
    }
    if(hasValue && value.lastVisitedHomeRoute) {
      navigate(value.lastVisitedHomeRoute)
      return
    }
    navigate(ROUTES.HOME_USER_PROJECTS.template)
  }, [ navigate, hasValue, value ])
  return null
}

export default HomeRedirect
