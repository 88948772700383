import { getIsActiveByPath } from "@/v2-console-shared/utils/utils.router"
import { useRootText } from "@/v2-console-shared/texts"
import Sidebar from "@/v2-layout-ui/sidebar"
import AppSidebarCurrentUserItem from "@/v2-console/app/sidebar/AppSidebarCurrentUserItem"
import HomeSidebarTenants from "@/v2-console/home/sidebar/HomeSidebarTenants"
import { useHomeNavigation } from "@/v2-console/home/home.utils"
import { ROUTES } from "@/v2-console/router/router.constants"

function HomeSidebar() {
  const { navigate } = useHomeNavigation()
  const { loading, getText } = useRootText()

  return (
    <Sidebar
      className="home-sidebar pt-2"
    >
      <Sidebar.Branding />
      <Sidebar.Content>
        <Sidebar.Group
        >
          <Sidebar.Item
            isLoading={loading}
            isDisabled
            className="home-sidebar__recent"
            iconSrc="clock"
            label={getText("UI_Home_Recent_Root").Name}
            isActive={getIsActiveByPath(ROUTES.HOME_RECENT.template)}
            onClick={() => {
              navigate(ROUTES.HOME_RECENT.template)
            }}
          />
          <Sidebar.Item
            isLoading={loading}
            className="home-sidebar__my-projects"
            iconSrc="apps"
            label={getText("UI_Home_UserProjects_Root").Name}
            isActive={getIsActiveByPath(ROUTES.HOME_USER_PROJECTS.template)}
            onClick={() => {
              navigate(ROUTES.HOME_USER_PROJECTS.template)
              // @todo: should remove the parameters of tenantId when clicking this
            }}
          />
        </Sidebar.Group>
        <Sidebar.Separator />
        <HomeSidebarTenants />
      </Sidebar.Content>
      <Sidebar.Separator />
      <Sidebar.Footer>
        <AppSidebarCurrentUserItem />
      </Sidebar.Footer>
    </Sidebar>
  )
}

export default HomeSidebar
