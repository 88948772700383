import { Point } from "@arcgis/core/geometry"
import Viewpoint from "@arcgis/core/Viewpoint"
import Camera from "@arcgis/core/Camera"
import type { Project } from "@/v2-common/__types__/schema.types"
import { useSearchParamOpener } from "@/v2-console-shared/utils/utils.searchParams"
import { useProjectQuery } from "@/v2-console/project/project.byId.query"
import { getViewpointFromStorage } from "@/v2-console/map/map.viewpoint"
import { useMemo } from "react"

export const PROJECT_ID_PARAM = "projectId"
export function useProjectOpener() {
  const opener = useSearchParamOpener(PROJECT_ID_PARAM)

  return opener
}

export function useGetCurrentProject() {
  const { activeValue } = useProjectOpener()
  const { data, loading } = useProjectQuery({
    skip: !activeValue,
    variables: {
      input: {
        id: activeValue
      }
    }
  })

  return {
    project: data?.getProjectById,
    loading
  }
}

function getProjectViewpointFromProject(project: Project) {
  if(!project.ThumbnailConfiguration?.viewpoint?.targetGeometry) return null

  const viewpoint = new Viewpoint({
    targetGeometry: new Point(project.ThumbnailConfiguration.viewpoint.targetGeometry),
    scale: project.ThumbnailConfiguration.viewpoint.scale ?? 5000
  })

  if(project.ThumbnailConfiguration.viewpoint.camera) {
    viewpoint.camera = new Camera({
      ...project.ThumbnailConfiguration.viewpoint.camera,
      position: new Point(project.ThumbnailConfiguration.viewpoint.camera.position)
    })
  }
  return viewpoint
}

export function getProjectViewpoint(project: Project) {
  return getViewpointFromStorage(project.Id)
    ?? getProjectViewpointFromProject(project)
    ?? null // fallback to null if no viewpoint is found
 }

export function useProjectsLookup(allProjects: Project[]) {
  return useMemo(() => {
    const projectsByProjectId: Record<string, Project> = {}
    const projectsByTenantId: Record<string, Project[]> = {}

    for(const project of allProjects) {
      projectsByProjectId[project.Id] = project

      if(!project.TenantIds) continue

      for(const tenantId of project.TenantIds) {
        if(!projectsByTenantId[tenantId]) {
          projectsByTenantId[tenantId] = []
        }
        projectsByTenantId[tenantId].push(project)
      }
    }

    return { projectsByProjectId, projectsByTenantId }
  }, [ allProjects ])
}
