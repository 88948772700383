import StateDisplay from "@/v2-layout-ui/state"
import { STATE_LOADING_TYPES } from "@/v2-layout-ui/state/loading/state.loading.constants"

export type FeatureLoadingFallbackProps = {
  className?: string
}
function FeatureLoadingFallback(props: FeatureLoadingFallbackProps) {
  const { className } = props

  return (
    <StateDisplay
      stateClassName={className}
      stateType={STATE_LOADING_TYPES.LOADING}
    />
  )
}

export default FeatureLoadingFallback
