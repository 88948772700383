import Sidebar from "@/v2-ui/sidebar"
import SidebarGroupSkeleton from "./SidebarGroupSkeleton"

export type SidebarGroupProps = {
  label?: string,
  className?: string,
  isLoading?: boolean,
  children: React.ReactNode
}

function SidebarGroup(props: SidebarGroupProps) {
  const { label, className, isLoading, children } = props

  if(isLoading) {
    return (
      <SidebarGroupSkeleton />
    )
  }

  return (
    <Sidebar.Group className={className}>
      {label
        ? <Sidebar.Group.Label>{label}</Sidebar.Group.Label>
        : null
      }
      <Sidebar.Menu>
        <Sidebar.Group.Content>
          {children}
        </Sidebar.Group.Content>
      </Sidebar.Menu>
    </Sidebar.Group>
  )
}

export default SidebarGroup
