import { useCallback, useMemo } from "react"
import { useSearchParamOpener } from "@/v2-console-shared/utils/utils.searchParams"
import { useNavigate } from "@/v2-console-shared/utils/utils.router"
import useLocalStorage from "@/v2-ui/utils/utils.localStorage"
import { useTenants } from "@/v2-console/currentUser/tenant/currentUser.tenant.utils"
import { TENANT_ID_PARAM } from "@/v2-console/home/home.constants"

type HomeTenantOpenerState = {
  lastVisitedHomeRoute: string,
  lastVisitedTenantId: number
}

export function useHomeTenantOpenerState() {
  const storage = useLocalStorage<HomeTenantOpenerState>("homeTenantOpener", {
    lastVisitedHomeRoute: "",
    lastVisitedTenantId: null
  })

  return storage
}

export function useHomeTenantOpener() {
  const { open, isActive, getIsActive, searchParams, close } = useSearchParamOpener(TENANT_ID_PARAM)
  const {
    userTenants
  } = useTenants()
  const tenant = useMemo(() =>
    userTenants.find(t => t.TenantId === Number.parseInt(searchParams.get(TENANT_ID_PARAM))),
    [ searchParams, userTenants ])

  return {
    open,
    close,
    isActive,
    getIsActive,
    tenant
  }
}

export function useHomeNavigation() {
  const { setItem } = useHomeTenantOpenerState()
  const { open, close, getIsActive } = useHomeTenantOpener()
  const navigate = useNavigate()

  const handleNavigation = useCallback((route: string, tenantId?: number) => {
    if(tenantId) {
      open(tenantId)
    }
    if(!tenantId) {
      close()
    }
    navigate(route)
    setItem({
      lastVisitedHomeRoute: route,
      lastVisitedTenantId: tenantId
    })
  }, [ close, navigate, open, setItem ])

  return {
    navigate: handleNavigation,
    getIsActive
  }
}

type HomeSidebarTenantsState = {
  open: boolean
}

type HomeSidebarTenantsStateLookupTable = Record<number, HomeSidebarTenantsState>

export function useHomeSidebarTenantsState() {
  const { setItem, value } = useLocalStorage<HomeSidebarTenantsStateLookupTable>("homeSidebarTenants", {})

  const getIsOpen = useMemo(() => (tenantId: number) => value[tenantId]?.open, [ value ])
  const handleToggleTenantOpen = useCallback((tenantId: number) => {
    setItem({
      ...value,
      [tenantId]: {
        open: !getIsOpen(tenantId)
      }
    })
  }, [ getIsOpen, setItem, value ])

  return {
    toggleTenantOpen: handleToggleTenantOpen,
    getIsOpen
  }
}
