import type { ReactNode } from "react"
import { Outlet } from "react-router-dom"
import ApolloClientProvider from "@/v2-console/apollo/ApolloClientProvider"
import AppContextLoader from "@/v2-console/app/context/AppContextLoader"
import TextProvider from "@/v2-console/text/TextProvider"
import { ROOT_TEXT, type RootTexts }
  from "@/v2-console-shared/texts"

// @TODO: check how i can optimize the different outlets we have for the app.
export type AppOutletProps = {
  children?: ReactNode
}
function AppOutlet(prop: AppOutletProps) {
  return (
    <ApolloClientProvider>
      <AppContextLoader>
        <TextProvider<RootTexts>
          isRoot
          fallbackTexts={ROOT_TEXT}
        >
          {/* @note: optionally lets the consumer control the outlet */}
          {prop.children
            ? prop.children
            : <Outlet />
          }
        </TextProvider>
      </AppContextLoader>
    </ApolloClientProvider>
  )
}

export default AppOutlet
