import { useNavigate } from "@/v2-console-shared/utils/utils.router"
import type { SidebarItemProps } from "@/v2-layout-ui/sidebar"
import { useRootText } from "@/v2-console-shared/texts"
import Sidebar from "@/v2-layout-ui/sidebar"
import { trackAppSidebarNavigated } from "@/v2-console/app/app.tracking"

export type AppSidebarItemHomeProps = Omit<SidebarItemProps,
  "label" | "iconSrc" | "onClick" | "className">

function AppSidebarItemHome(props: AppSidebarItemHomeProps) {
  const { getText, loading } = useRootText()
  const navigate = useNavigate()
  return (
    <Sidebar.Item
      className="app-sidebar__to-home"
      iconSrc="home"
      label={getText("UI_Home_Root").Name}
      isLoading={loading}
      onClick={() => {
        navigate("/home", { replace: true }, false, false)
        trackAppSidebarNavigated("Home")
      }}
      {...props}
    />
  )
}

export default AppSidebarItemHome
