import { useCallback, type ComponentProps } from "react"
import cn from "@/v2-ui/utils/utils.cn"
import SidebarBase from "@/v2-ui/sidebar"
import { useSidebarContext } from "@/v2-layout-ui/sidebar"

export type SidebarProps = ComponentProps<"div"> &{
  rootId?: string
}

function Sidebar(props: SidebarProps) {
  const { rootId, className, children } = props
  const { isMini, setIsMini } = useSidebarContext()

  return (
    <SidebarBase
      rootId={rootId}
      variant="sidebar"
      collapsible="icon"
      className={cn("relative flex", className, {
        "is-mini": isMini
      })}
    >
      {children}
      <SidebarBase.Rail
        onDragged={useCallback((direction: string) => {
          if(direction === "left" && !isMini) {
            setIsMini(true)
          }
          if(direction === "right" && isMini) {
            setIsMini(false)
          }
        }, [ isMini, setIsMini ])}
        className="hidden md:block"
      />
    </SidebarBase>
  )
}

export default Sidebar
