import Sidebar from "@/v2-ui/sidebar"

function SidebarGroupSkeleton() {
  return (
    <Sidebar.Group>
      <Sidebar.Menu>
        <Sidebar.Menu.Skeleton />
      </Sidebar.Menu>
    </Sidebar.Group>
  )
}

export default SidebarGroupSkeleton
