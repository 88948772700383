import { STATE_ERROR_TYPES } from "@/v2-layout-ui/state/error/state.error.constants"
import type { StateErrorType } from "@/v2-layout-ui/state/error/state.error.types"
import StateErrorNotFound from "@/v2-layout-ui/state/error/StateErrorNotFound"
import StateErrorDefault from "@/v2-layout-ui/state/error/StateErrorDefault"

export type StateErrorProps = {
  stateErrorType: StateErrorType
  className?: string
}

function StateError(props: StateErrorProps) {
  const { stateErrorType, className } = props

  if(stateErrorType === STATE_ERROR_TYPES.NOT_FOUND) {
    return (
      <StateErrorNotFound
        className={className}
      />
    )
  }
  if(stateErrorType === STATE_ERROR_TYPES.DEFAULT) {
    return (
      <StateErrorDefault
        className={className}
      />
    )
  }
  return null
}

export default StateError
