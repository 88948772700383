import { useRootText } from "@/v2-console-shared/texts"
import Sidebar from "@/v2-layout-ui/sidebar"
import cn from "@/v2-ui/utils/utils.cn"
import Avatar from "@/v2-ui/avatar"
import { useHomeNavigation, useHomeSidebarTenantsState } from "@/v2-console/home/home.utils"
import { useTenants } from "@/v2-console/currentUser/tenant/currentUser.tenant.utils"
import { ROUTES } from "@/v2-console/router/router.constants"

function HomeSidebarTenants() {
  const {
    userTenants
  } = useTenants()

  const { navigate, getIsActive } = useHomeNavigation()
  const { loading, getText } = useRootText()
  const { toggleTenantOpen, getIsOpen } = useHomeSidebarTenantsState()

  return (
    <Sidebar.Group
      label={getText("UI_Home_Tenants_Root").Name}
      isLoading={loading}
    >
      {userTenants.map((tenant) => (
        <Sidebar.CollapsibleItem
          renderBefore={
            // @note: hack to align the avatar in the center with right classname
            <Avatar
              className="h-5 w-5 text-xs right-0.5"
            >
              <Avatar.Fallback
                className={cn({
                  "bg-primary text-background": getIsActive(tenant.TenantId)
                })}
              >
                {tenant.Tenant.Name[0]}
              </Avatar.Fallback>
            </Avatar>
          }
          key={tenant.TenantId}
          label={tenant.Tenant.Name}
          tooltip={`${getText("UI_Home_Tenant_Root").Name}: ${tenant.Tenant.Name}`}
          onClick={() => {
            toggleTenantOpen(tenant.TenantId)
          }}
          open={getIsOpen(tenant.TenantId)}
          subItems={[
            {
              label: getText("UI_Home_Tenant_Projects_Root").Name,
              iconSrc: "apps",
              onClick: () => {
                navigate(ROUTES.HOME_TENANT_PROJECTS.template, tenant.TenantId)
              },
              isActive: getIsActive(tenant.TenantId)
            },
            {
              label: getText("UI_Home_Tenant_Deleted_Root").Name,
              iconSrc: "trash",
              onClick: () => { },
              isDisabled: true
            },
            {
              label: getText("UI_Home_Tenant_Admin_Root").Name,
              iconSrc: "settings",
              onClick: () => { },
              isDisabled: true
            }
          ]}
        />
      ))}
    </Sidebar.Group>
  )
}

export default HomeSidebarTenants
