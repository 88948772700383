import type { ReactNode } from "react"
import { useCallback } from "react"
import { useSidebarContext } from "@/v2-layout-ui/sidebar"
import type { IconNames } from "@/v2-ui/icon/icon.types"
import DropdownMenu from "@/v2-ui/dropdownMenu"
import Collapsible from "@/v2-ui/collapsible"
import cn from "@/v2-ui/utils/utils.cn"
import Sidebar from "@/v2-ui/sidebar"
import Badge from "@/v2-ui/badge"
import Icon from "@/v2-ui/icon"
import CollapsibleSubItem
  from "@/v2-layout-ui/sidebar/SidebarCollapsibleSubItem"
import SidebarItemSkeleton
  from "@/v2-layout-ui/sidebar/SidebarItemSkeleton"

export type SubItems = {
  label: string,
  iconSrc?: IconNames,
  onClick: () => void,
  badge?: string,
  isActive?: boolean,
  isDisabled?: boolean
}

export type SidebarCollapsibleItemProps = {
  label: string,
  iconSrc?: IconNames,
  subItems: SubItems[],
  tooltip?: string,
  isLoading?: boolean,
  onClick?: () => void,
  open?: boolean,
  renderBefore?: ReactNode
}

function SidebarCollapsibleItem(props: SidebarCollapsibleItemProps) {
  const {
    label,
    iconSrc,
    subItems,
    isLoading,
    tooltip,
    onClick,
    open,
    renderBefore
  } = props

  const { isMini } = useSidebarContext()

  const renderCollapsibleButton = useCallback(() => {
    if(isLoading) {
      return (
        <SidebarItemSkeleton showIcon={!!iconSrc} />
      )
    }
    const collapsibleButton = (
      <Sidebar.Menu.Button
        tooltip={tooltip || label}
        onClick={onClick}
      >
        {renderBefore}
        {iconSrc ? <Icon src={iconSrc} /> : null}
        <span className="truncate">{label}</span>
        <Icon
          src="angle-small-right"
          className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90"
        />
      </Sidebar.Menu.Button>
    )

    // render a dropdown menu onClick if the sidebar is mini
    if(isMini) {
      return (
        <DropdownMenu>
          <DropdownMenu.Trigger>
            {collapsibleButton}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            side="right"
            sideOffset={14}
          >
            <DropdownMenu.Label>{label}</DropdownMenu.Label>
            {subItems.map((subItem, index) => (
              <DropdownMenu.Item
                key={index}
                className={cn("flex gap-2", subItem.isActive && "bg-accent")}
                onClick={() => {
                  // @todo: onClick triggers the tooltip to show after closing the dropdown
                  // e.preventDefault()
                  subItem.onClick()
                }}
                disabled={subItem.isDisabled}
              >
                {iconSrc ? <Icon src={iconSrc} /> : null}
                {subItem.label}
                {subItem.badge && <Badge className="ml-1">{subItem.badge}</Badge>}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu>
      )
    }
    return collapsibleButton
  }, [
    iconSrc,
    label,
    tooltip,
    isLoading,
    isMini,
    subItems,
    renderBefore,
    onClick
  ])

  return (
    <Sidebar.Menu>
      <Collapsible
        open={open}
        className="group/collapsible"
        asChild
      >
        <Sidebar.Menu.Item>
          <Collapsible.Trigger asChild>
            {renderCollapsibleButton()}
          </Collapsible.Trigger>
          <Collapsible.Content>
            <Sidebar.Menu.Sub>
              {subItems.map((subItem, index) => (
                <CollapsibleSubItem
                  key={index}
                  label={subItem.label}
                  iconSrc={subItem.iconSrc}
                  onClick={subItem.onClick}
                  badge={subItem.badge}
                  isActive={subItem.isActive}
                  isLoading={isLoading}
                  isDisabled={subItem.isDisabled}
                />
              ))}
            </Sidebar.Menu.Sub>
          </Collapsible.Content>
        </Sidebar.Menu.Item>
      </Collapsible>
    </Sidebar.Menu>
  )
}

export default SidebarCollapsibleItem
