import { useCallback, useEffect, useState } from "react"
import type { ReactNode } from "react"
import type { SidebarMenuItemProps, SidebarMenuButtonProps }
  from "@/v2-ui/sidebar"
import type { IconNames } from "@/v2-ui/icon/icon.types"
import { useIsMobile } from "@/v2-ui/utils/utils.mobile"
import DropdownMenu from "@/v2-ui/dropdownMenu"
import Popover from "@/v2-ui/popover"
import Sidebar from "@/v2-ui/sidebar"
import Badge from "@/v2-ui/badge"
import Icon from "@/v2-ui/icon"
import SidebarItemSkeleton
  from "@/v2-layout-ui/sidebar/SidebarItemSkeleton"
import Button from "@/v2-ui/button"

const CONTENT_SIDE = "right"
const CONTENT_SIDE_OFFSET = 14

export type SidebarItemProps = Omit<SidebarMenuItemProps, "children"> & {
  label?: string,
  iconSrc?: IconNames,
  onClick?: () => void,
  onTrack?: () => void,
  tooltip?: string,
  badge?: ReactNode,
  size?: SidebarMenuButtonProps["size"],
  isLoading?: boolean
  isActive?: boolean,
  isDisabled?: boolean,
  popoverContent?: (open: boolean, onClose: () => void) => ReactNode,
  dropdownMenuContent?: (open: boolean, onClose: () => void) => ReactNode,
  contentSize?: "lg" | "md",
  actionDropdownContent?: React.ReactNode,
  actionIconSrc?: IconNames,
}

function SidebarItem(props: SidebarItemProps) {
  const {
    label,
    iconSrc,
    onClick,
    onTrack,
    tooltip,
    badge,
    size,
    isLoading,
    isActive: _isActive,
    isDisabled,
    popoverContent,
    dropdownMenuContent,
    contentSize,
    actionDropdownContent,
    actionIconSrc = "menu-dots",
    ...sidebarMenuItemProps
  } = props

  const [ isActive, setIsActive ] = useState(_isActive)
  const [ open, setOpen ] = useState(false)
  const isMobile = useIsMobile()

  useEffect(() => {
    setIsActive(_isActive)
  }, [ _isActive ])

  const onClose = useCallback(() => {
    setOpen(false)
    setIsActive(false)
  }, [])

  const renderButton = useCallback(() => {
    if(isLoading) {
      return (
        <SidebarItemSkeleton showIcon={!!iconSrc} />
      )
    }

    let t: string | ReactNode = tooltip || label
    if(badge) {
      t = <span>{t} <Badge variant="secondary" className="ml-1">{badge}</Badge></span>
    }
    return (
      <Sidebar.Menu.Button
        size={size}
        isActive={isActive}
        tooltip={isActive ? null : t}
        asChild
      >
        <Button
          className="!justify-start" // @note: hack to align the text to the left
          variant="ghost"
          disabled={isDisabled}
        >
          {iconSrc ? <Icon src={iconSrc} /> : null}
          <span className="truncate">{label}</span>
          {badge && <Badge>{badge}</Badge>}
        </Button>
      </Sidebar.Menu.Button>
    )
  }, [
    label,
    iconSrc,
    tooltip,
    badge,
    size,
    isActive,
    isLoading,
    isDisabled
  ])

  const renderActionDropdown = useCallback(() => {
    if(!actionDropdownContent || isLoading) return null
    return (
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Sidebar.Menu.Action showOnHover>
            <Icon src={actionIconSrc} />
            <span className="sr-only">More</span>
          </Sidebar.Menu.Action>
        </DropdownMenu.Trigger>
        {actionDropdownContent}
      </DropdownMenu>
    )
  }, [
    actionDropdownContent,
    actionIconSrc,
    isLoading
  ])

  if(dropdownMenuContent) {
    return (
      <Sidebar.Menu.Item
        onClick={() => onClick?.()}
      >
        <DropdownMenu
          open={open}
          onOpenChange={(v: boolean) => {
            setOpen(v)
            setIsActive(v)
          }}
        >
          <DropdownMenu.Trigger asChild>
            {renderButton()}
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            sideOffset={CONTENT_SIDE_OFFSET}
            side={CONTENT_SIDE}
            size={contentSize}
          >
            {dropdownMenuContent(open, onClose)}
          </DropdownMenu.Content>
        </DropdownMenu>
        {renderActionDropdown()}
      </Sidebar.Menu.Item>
    )
  }

  if(popoverContent) {
    return (
      <Sidebar.Menu.Item
        onClick={() => onClick?.()}
      >
        <Popover
          open={open}
          onOpenChange={(v: boolean) => {
            if(v) {
              onTrack?.()
            }
            setOpen(v)
            setIsActive(v)
          }}
        >
          <Popover.Trigger asChild>
            {renderButton()}
          </Popover.Trigger>
          <Popover.Content
            sideOffset={isMobile ? 0 : CONTENT_SIDE_OFFSET}
            side={CONTENT_SIDE}
            size={contentSize}
          >
            {popoverContent(open, onClose)}
          </Popover.Content>
        </Popover>
        {renderActionDropdown()}
      </Sidebar.Menu.Item>
    )
  }

  return (
    <Sidebar.Menu.Item
      onClick={() => onClick?.()}
      {...sidebarMenuItemProps}
    >
      {renderButton()}
      {renderActionDropdown()}
    </Sidebar.Menu.Item>
  )
}

export default SidebarItem
