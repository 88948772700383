const ENV_NAME = process.env.ENV_NAME || ""
const SLOT = process.env.SLOT || "production"
const ENV_TYPE = process.env.ENV_TYPE || "dev"
const LOG_LEVEL = process.env.LOG_LEVEL || "debug"
const VERSION = process.env.VERSION || "v0.0.0"
const IS_TEST = process.env.IS_TEST === "true"
const IS_REMOTE_AUTH = process.env.IS_REMOTE_AUTH === "true"
const IS_REMOTE_GRAPH = process.env.IS_REMOTE_GRAPH === "true"
const REGION = process.env.REGION
  || process.env.REGION_NAME
  || "local"

const config = {
  /**
   * When a deployment is created from a PR, the name of the deployment
   * is the same as the source branch
   */
  envName: ENV_NAME,

  /**
   * Which environment the app is running in. There are 4 types of environments:
   * - dev: running locally on a developer's machine
   * - prod: running either as a released or staged version remotely
   */
  envType: ENV_TYPE,
  /** Running on production (can be released or staged) */
  isProd: ENV_TYPE === "prod",
  /** Running locally on developer's computer */
  isDev: ENV_TYPE === "dev",

  /** Which deployment region is hosting the app (eastno, westeu or local) */
  region: REGION,
  /** Running locally on developer's computer */
  isLocal: REGION === "local",
  /** Running remotely in Azure */
  isRemote: REGION !== "local",
  /** Running on a failover region (westeu) */
  isFailover: REGION === "westeu",

  /** Running tests */
  isTest: IS_TEST,
  /** Running as a released deployment */
  isRelease: ENV_NAME === "release",
  /** Running as a staged deployment */
  isStaging: ENV_NAME === "staging",
  /**
   * Running remotely in the stable dev environment
   * (can be used as a fallback resource for other environments)
   */
  isNext: ENV_NAME === "next",

  /**
   * Forces auth to be configured remotely. Useful when the app starts
   * locally, but only partially
   */
  isRemoteAuth: IS_REMOTE_AUTH,
  /**
   * Forces clients to use the remote supergraph. Useful when the app starts
   * locally, but only partially
   */
  isRemoteGraph: IS_REMOTE_GRAPH,

  /**
   * Azure App Service can have multiple deployment slots, which are essentially copies
   * of the app running on the same underlying VM, but as different container. Currently
   * only the production resources are configured with multiple slot, one for release
   * called "production" and one for staging called "staging". All other resources have
   * only one slot called "production" - this is a bit confusing and should not be compared
   * with a production release of the application
   */
  slot: SLOT,
  /** dev/pre-releases will be postfixed with a short commit SHA */
  version: VERSION,

  /** used to filter the granularity of logging calls */
  logLevel: LOG_LEVEL,

  /**
   * Used for tracking the deployment with the following format:
   * `{config.envName}@{config.envType}-{config.version}-{config.region}-{config.slot}`
   */
  envHeader: "",

  /** The app will send performance metrics (ie. sentry tracing) */
  isInstrumentationEnabled: false,
  /** The app will send usage tracking (ie. segment/mixpanel) */
  isTrackingEnabled: false,

  /** Source is Hubspot. Determines if the CurrentUser has admin access */
  placepointExternalCustomerId: "6894908674",

  azAuthLoginDomain: "login-next.placepoint.no",
  azAuthRegisterPolicyName: "B2C_1_register",
  azAuthLoginPolicyName: "B2C_1_login",
  azAuthTestPolicyName: "B2C_1_ropc",

  layerServerBaseUrl: "https://layers2.placepoint.no/arcgis/rest/services",
  geodataServerBaseUrl: "https://services.geodataonline.no/arcgis/rest/services"
}

if(config.isTest) {
  config.azAuthLoginPolicyName = "B2C_1_ropc"
}

if(config.isRemote) {
  config.isInstrumentationEnabled = true
}

if(config.isProd) {
  config.isTrackingEnabled = true
  config.azAuthLoginDomain = "login.placepoint.no"
}

config.envHeader
  = `${config.envName}@${config.version}-${config.region}-${config.slot}`

export default config
